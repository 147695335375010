import(/* webpackMode: "eager", webpackExports: ["getSession"] */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.2.3_@babel+core@7.26.10_babel-plugin-macros@3.1.0_react-dom@1_scyz5bmdlzz4cagvqgg63tjcdm/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_babel-plugin-macros@3.1.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_babel-plugin-macros@3.1.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../../public/fonts/Aleo-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../../../public/fonts/Aleo-Bold.ttf\",\"weight\":\"700\"}],\"variable\":\"--font-aleo\"}],\"variableName\":\"aleo\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/packages/app-core/src/lib/providers/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/packages/common/src/components/ui/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/packages/common/src/components/ui/TopLevelUseClient.tsx");
;
import(/* webpackMode: "eager" */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/packages/common/src/styles/globals.css");
