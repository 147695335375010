import { createSlice } from "@reduxjs/toolkit";
import { SafeUser } from "@/src/utils/stripSensitiveUserData";
import { GeneratorData, Section } from "@/types/formGenerator";
import { TeamWithRelations } from "@/app-core/src/app/queries/getUserInTeams";

// Define types for 'entry' and 'reports' states
export type EntryState = {
  title: string;
  id: string | null;
  internalNote: string;
  createdDate: Date | undefined;
  entryTemplate: string;
  entry: GeneratorData; // Replace 'any' with the specific type of the 'entry' state if possible
  defaultFormValues: Record<string, any>;
  updatedDefaultEntryValues: Record<string, any>;
  entryLastSaved: GeneratorData;
  entryFormValidated: boolean;
  reportsLastSaved: GeneratorData;
  reportsFormValidated: boolean;
  dataState: number[]; // -2 = no data, -1 = have data, 0 = formDataProcessed, 1 = data stable - ready for user edits now, 2 = user has edited data
  isApproved: boolean;
  isDownloaded: boolean;
  versionSuffixs: string[];
  isArchived: boolean;
  reportsTemplate: string;
  reports: GeneratorData; // Replace 'any' with the specific type of the 'reports' state if possible
  updatedDefaultReportsValues: Record<string, any>;
  regardingKey: string | undefined;
  filesDownloaded: string[];
  reportsForApproval: string[];
  reportsApproved: string[] | null;
  ownerFields: Record<string, any>;
  clientFields: Record<string, any>;
  officeFields: Record<string, any>;
  entryFieldOptions: Record<string, any>;
  reportFieldOptions: Record<string, any>;
  team: TeamWithRelations | undefined;
  editors: SafeUser[];
  userEditedFields: Record<string, any>;
  hiddenFields: Record<"tabs", Record<string, string[]>> | undefined;
  menuOptionNotifications: string[];
  proposedFieldChangesAddressed: string[];
  timeEntryLastSavedToday?: number;
  timeReportsLastSavedToday?: number;
  extraReports: { [key: string]: Section }[];
  runAIFillOnFirstLoadTabs?: string[];
  sensitiveFields: string[];
};

export type ReportsState = {
  reports: GeneratorData; // Replace 'any' with the specific type of the 'reports' state if possible
};

const entrySlice = createSlice({
  name: "entry", // Name of the slice
  initialState: {
    title: "",
    id: null,
    internalNote: "",
    createdDate: undefined,
    entryTemplate: "",
    entry: {
      makeDependentFunctionsForAllDynamicFields: true,
      sections: {},
    }, // Initial state for 'entry' JSON states
    defaultFormValues: {},
    updatedDefaultEntryValues: {},
    entryLastSaved: {
      sections: {},
    },
    reportsTemplate: "",
    reports: {
      makeDependentFunctionsForAllDynamicFields: true,
      sections: {},
    }, // Initial state for 'reports' states
    updatedDefaultReportsValues: {},
    reportsLastSaved: {
      sections: {},
    },
    regardingKey: undefined,
    hiddenFields: undefined,
    reportsForApproval: [], //array of report ids that have been approved
    reportsApproved: null,
    filesDownloaded: [],
    reportsFormValidated: false, // Initial form validation state
    entryFormValidated: false, // Initial form validation state
    dataState: [-2, -2],
    isApproved: false,
    isDownloaded: false,
    versionSuffixs: [],
    isArchived: false,
    ownerFields: {},
    team: undefined,
    editors: [],
    userEditedFields: {},
    clientFields: {},
    officeFields: {},
    entryFieldOptions: {},
    reportFieldOptions: {},
    menuOptionNotifications: [],
    proposedFieldChangesAddressed: [],
    timeEntryLastSavedToday: undefined,
    timeReportsLastSavedToday: undefined,
    extraReports: [],
    runAIFillOnFirstLoadTabs: [],
    sensitiveFields: [],
  },
  reducers: {
    setEntryTitle: (state, action) => {
      state.title = action.payload;
    },
    setEntryId: (state, action) => {
      state.id = action.payload;
    },
    setInternalNote: (state, action) => {
      state.internalNote = action.payload;
    },
    setCreatedDate: (state, action) => {
      state.createdDate = action.payload;
    },
    setEntryTemplate: (state, action) => {
      state.entryTemplate = action.payload;
    },
    setEntryMakeDependentFunctionsForAllDynamicFieldsEntry: (state, action) => {
      state.entry.makeDependentFunctionsForAllDynamicFields = action.payload;
    },
    setEntryMakeDependentFunctionsForAllDynamicFieldsReports: (state, action) => {
      state.reports.makeDependentFunctionsForAllDynamicFields = action.payload;
    },
    setEntrySections: (state, action) => {
      state.entry.sections = action.payload;
    },
    setDefaultFormValues: (state, action) => {
      state.defaultFormValues = action.payload;
    },
    setUpdatedDefaultEntryValues: (state, action) => {
      state.updatedDefaultEntryValues = action.payload;
    },
    setEntryFieldsLastSaved: (state, action) => {
      state.entryLastSaved.sections = action.payload;
    },
    setReportsTemplate: (state, action) => {
      state.reportsTemplate = action.payload;
    },
    setReportsData: (state, action) => {
      state.reports = action.payload;
    },
    setReportsSections: (state, action) => {
      state.reports.sections = action.payload;
    },
    setUpdatedDefaultReportsValues: (state, action) => {
      state.updatedDefaultReportsValues = action.payload;
    },
    setReportsLastSaved: (state, action) => {
      state.reportsLastSaved.sections = action.payload;
    },
    setRegardingKey: (state, action) => {
      state.regardingKey = action.payload;
    },
    setEntryDataState: (state, action) => {
      state.dataState = [action.payload, state.dataState[0]];
    },
    setReportsDataState: (state, action) => {
      state.dataState = [action.payload, state.dataState[0]];
    },
    setFilesDownloaded: (state, action) => {
      state.filesDownloaded = action.payload;
    },
    setEntryFormValidated: (state, action) => {
      state.entryFormValidated = action.payload;
    },
    setReportsFormValidated: (state, action) => {
      state.reportsFormValidated = action.payload;
    },
    setReportsForApproval: (state, action) => {
      state.reportsForApproval = action.payload;
    },
    setReportsApproved: (state, action) => {
      state.reportsApproved = action.payload;
    },
    setIsApproved: (state, action) => {
      state.isApproved = action.payload;
    },
    setIsDownloaded: (state, action) => {
      state.isDownloaded = action.payload;
    },
    setVersionSuffixs: (state, action) => {
      state.versionSuffixs = action.payload;
    },
    setIsArchived: (state, action) => {
      state.isArchived = action.payload;
    },
    setOwnerFields: (state, action) => {
      state.ownerFields = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
    setEditors: (state, action) => {
      state.editors = action.payload;
    },
    setUserEditedFields: (state, action) => {
      state.userEditedFields = action.payload;
    },
    setClientFields: (state, action) => {
      state.clientFields = action.payload;
    },
    setOfficeFields: (state, action) => {
      state.officeFields = action.payload;
    },
    setEntryFieldOptions: (state, action) => {
      state.entryFieldOptions = action.payload;
    },
    setReportFieldOptions: (state, action) => {
      state.reportFieldOptions = action.payload;
    },
    setHiddenFields: (state, action) => {
      state.hiddenFields = action.payload;
    },
    setMenuOptionNotifications: (state, action) => {
      state.menuOptionNotifications = action.payload;
    },
    setProposedFieldChangesAddressed: (state, action) => {
      state.proposedFieldChangesAddressed = action.payload;
    },
    setTimeEntryLastSavedToday: (state, action) => {
      state.timeEntryLastSavedToday = action.payload;
    },
    setTimeReportsLastSavedToday: (state, action) => {
      state.timeReportsLastSavedToday = action.payload;
    },
    setExtraReports: (state, action) => {
      state.extraReports = action.payload;
    },
    setRunAIFillOnFirstLoadTabs: (state, action) => {
      state.runAIFillOnFirstLoadTabs = action.payload;
    },
    setSensitiveFields: (state, action) => {
      state.sensitiveFields = action.payload;
    },
    resetEntryState: (state) => {
      state.id = null;
      state.title = "";
      state.internalNote = "";
      state.createdDate = undefined;
      state.entryTemplate = "";
      state.entry = {
        makeDependentFunctionsForAllDynamicFields: true,
        sections: {},
      };
      state.defaultFormValues = {};
      state.updatedDefaultEntryValues = {};
      state.entryLastSaved = {
        sections: {},
      };
      state.reportsLastSaved = {
        sections: {},
      };
      state.reportsTemplate = "";
      state.reports = {
        makeDependentFunctionsForAllDynamicFields: true,
        sections: {},
      };
      state.updatedDefaultReportsValues = {};
      state.reportsLastSaved = {
        sections: {},
      };
      state.regardingKey = undefined;
      state.reportsFormValidated = false;
      state.entryFormValidated = false;
      state.reportsForApproval = [];
      state.reportsApproved = null;
      state.dataState = [-2, -2];
      state.isApproved = false;
      state.isDownloaded = false;
      state.versionSuffixs = [];
      state.isArchived = false;
      state.filesDownloaded = [];
      state.team = undefined;
      state.editors = [];
      state.userEditedFields = {};
      state.ownerFields = {};
      state.officeFields = {};
      state.entryFieldOptions = {};
      state.reportFieldOptions = {};
      state.hiddenFields = undefined;
      state.menuOptionNotifications = [];
      state.clientFields = {};
      state.proposedFieldChangesAddressed = [];
      state.timeEntryLastSavedToday = undefined;
      state.timeReportsLastSavedToday = undefined;
      state.extraReports = [];
      state.runAIFillOnFirstLoadTabs = [];
      state.sensitiveFields = [];
    },
  },
});

export const {
  setEntryTitle,
  setEntryId,
  setInternalNote,
  setCreatedDate,
  setEntryTemplate,
  setEntryMakeDependentFunctionsForAllDynamicFieldsEntry,
  setEntryMakeDependentFunctionsForAllDynamicFieldsReports,
  setEntrySections,
  setDefaultFormValues,
  setUpdatedDefaultEntryValues,
  setEntryFieldsLastSaved,
  setReportsTemplate,
  setReportsData,
  setReportsSections,
  setUpdatedDefaultReportsValues,
  setReportsLastSaved,
  setRegardingKey,
  setReportsFormValidated,
  setEntryFormValidated,
  setReportsForApproval,
  setReportsApproved,
  setEntryDataState,
  setReportsDataState,
  setIsApproved,
  setIsDownloaded,
  setVersionSuffixs,
  setIsArchived,
  setOwnerFields,
  setEditors,
  setUserEditedFields,
  setTeam,
  setClientFields,
  setOfficeFields,
  setFilesDownloaded,
  setEntryFieldOptions,
  setReportFieldOptions,
  setHiddenFields,
  setMenuOptionNotifications,
  setProposedFieldChangesAddressed,
  setTimeEntryLastSavedToday,
  setTimeReportsLastSavedToday,
  setExtraReports,
  setRunAIFillOnFirstLoadTabs,
  resetEntryState,
  setSensitiveFields,
} = entrySlice.actions;
export default entrySlice.reducer;
