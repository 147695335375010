"use client";

import React, { ReactNode } from "react";
import { useErrorBoundary, withErrorBoundary } from "react-use-error-boundary";
import { ModalLayout } from "@/common/src/components/modals/ModalLayout";
import { PageContentContainer } from "@/common/src/components/ui/PageContentContainer";
import { Button } from "@/common/src/components/ui/Button";
import axios from "axios";
import { ErrorToObj, removeCircularReferences } from "@/common/src/utils/objectManipulation";
import { objectToTableRows } from "@/common/src/utils/email";
import { LastInteraction } from "@/common/src/components/ui/TopLevelUseClient";
import { isDevEnv } from "@/common/src/utils/environments";

interface GlobalErrorBoundaryProps {
  children?: ReactNode;
  initialUser?: any;
  getLastInteraction?: () => LastInteraction | null;
}

// A helper function to log and report errors.
export const reportError = async (
  error: Error | Record<string, unknown>,
  errorInfo: { componentStack: string },
  currentUser?: any | null,
  getLastInteraction?: () => LastInteraction | null
): Promise<void> => {
  const errorData = {
    userEmail: currentUser?.email,
    error: ErrorToObj(error),
    componentStack: errorInfo.componentStack,
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    url: window.location.href,
    pathname: window.location.pathname,
    time: new Date().toISOString(),
    isOnline: navigator.onLine,
    lastInteraction: getLastInteraction ? getLastInteraction() : {},
    isReactError: true,
  };

  let htmlTableRows;
  try {
    htmlTableRows = objectToTableRows(errorData);
  } catch (e) {
    const noCircularReferenceData = removeCircularReferences(errorData);
    htmlTableRows = objectToTableRows(noCircularReferenceData);
  }

  console.error("ErrorBoundary captured error:", htmlTableRows);

  if (isDevEnv()) return;

  //await axios.post("/api/clientSideError", { data: htmlTableRows, note: "From GlobalErrorBoundary" });
  // Use Promise without await
  axios.post("/api/clientSideError", { data: htmlTableRows, note: "From GlobalErrorBoundary" }).catch((postError) => {
    console.error("Failed to post error:", postError);
  });
};

/*
WARNING:
Because React recreates the component tree from scratch after catching an error, 
the component using the useErrorBoundary hook is always remounted after an error 
is encountered. This means any state will be reinitialized: useState and useRef 
hooks will be reinitialized to their initial value and will not persist across 
caught errors. Any values that need to be preserved across error catching must 
be lifted into a parent component above the component wrapped in withErrorBoundary.
*/

function GlobalErrorBoundaryComponent({ children, initialUser, getLastInteraction }: GlobalErrorBoundaryProps) {
  // Use the useErrorBoundary hook with a callback to log/report errors.
  const [error, resetError] = useErrorBoundary((err, errorInfo) => {
    reportError(err as Error, { componentStack: String(errorInfo) }, initialUser, getLastInteraction);
  });

  if (error) {
    console.error("ErrorBoundary captured error:", error);
    return (
      <PageContentContainer variant="centeredXY">
        <ModalLayout title="An unexpected error occurred" variant="error">
          <div>
            <p>{error.hasOwnProperty("message") ? (error as Error).message : "An unexpected error occurred"}</p>
          </div>
          <Button
            onClick={() => {
              resetError();
              window.location.reload();
            }}
          >
            Retry
          </Button>
        </ModalLayout>
      </PageContentContainer>
    );
  }

  return <>{children}</>;
}

// Wrap the component with the withErrorBoundary HOC for additional error boundary functionality.
export const GlobalErrorBoundary = withErrorBoundary(GlobalErrorBoundaryComponent);

export default GlobalErrorBoundary;
